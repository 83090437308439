import {
    postRequest,
    getRequest
} from "./request";
// 大数据
export const big_data = {
    cjbl:data => postRequest("/big_data/cjbl", data, false),
    problemAnalysisByMonth:data => postRequest("/big_data/problemAnalysisByMonth", data, false),
    gzdt:data => postRequest("/big_data/gzdt", data, false),
    index:data => postRequest("/big_data/index", data, false),
    
}
export const staff = {
    query:data => postRequest("/staff/query", data, false)
    
}
export const problem = {
    query:data => postRequest("/problem/query", data, false)
    
}
export const article = {
    query:data => postRequest("/article/query", data, false)
    
}

