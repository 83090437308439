<template>
  <div class="index flex between">
    <div class="ball"></div>
    <div class="left">
      <div class="top">
        <div class="title">工作动态分析情况</div>
        <div class="flex container ">
          <div class="grid flex" @click="GetarticleQuery('1',2,'工作动态')">
            <div class="logo-1"></div>
            <div style="margin-left: 0.5vw">
              <div style="
                  margin-top: 1vh;
                  font-size: 2.08333vw;
                  color: #00ccff;
                  font-weight: bold;
                ">
                {{ gzdt_data.gz }}
              </div>
              <div style="font-size: 0.72917vw; color: #c0c3da">工作数量</div>
            </div>
          </div>
          <div class="grid flex" @click="GetarticleQuery('7,8,9,10',2,'学习园地')">
            <div class="logo-2"></div>
            <div style="margin-left: 0.5vw">
              <div style="
                  margin-top: 1vh;
                  font-size: 2.08333vw;
                  color: #00a9a4;
                  font-weight: bold;
                ">
                {{ gzdt_data.xxyd }}
              </div>
              <div style="font-size: 0.72917vw; color: #c0c3da">学习园地数量</div>
            </div>
          </div>
          <div class="grid flex" @click="GetarticleQuery('4',2,'新闻动态')">
            <div class="logo-3"></div>
            <div style="margin-left: 0.5vw">
              <div style="
                  margin-top: 1vh;
                  font-size: 2.08333vw;
                  color: #7584ff;
                  font-weight: bold;
                ">
                {{ gzdt_data.xwdt }}
              </div>
              <div style="font-size: 0.72917vw; color: #c0c3da">新闻动态数量</div>
            </div>
          </div>
          <div class="grid flex">
            <div class="logo-4"></div>
            <div style="margin-left: 0.5vw">
              <div style="
                  margin-top: 1vh;
                  font-size: 2.08333vw;
                  color: #ff735d;
                  font-weight: bold;
                ">
                {{ gzdt_data.fk }}
              </div>
              <div style="font-size: 0.72917vw; color: #c0c3da">反馈总数量</div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <div class="title">各村级办理情况</div>
        <div id="village"></div>
      </div>
    </div>
    <div class="center">
      <div class="num flex">
        <div class="tips-bg">
          <div style="position: absolute; top: 2.5vh; left: 2vw; font-size: 1.04167vw">
            <span style="color: #00fcff; margin-right: 0.5vw">已办数量</span>
            <span style="color: #44ff5d">{{ index_data.yb }}条</span>
          </div>
        </div>
        <div class="tips-bg">
          <div style="position: absolute; top: 2.5vh; left: 2vw; font-size: 1.04167vw">
            <span style="color: #00fcff; margin-right: 0.5vw">待办数量</span>
            <span style="color: #ff4444">{{ index_data.db }}条</span>
          </div>
        </div>
      </div>

      <div class="bottom">
        <div class="title">意见建议</div>
        <div class="content">
          <div class="flex between" style="color: #fff; text-align: center">
            <div style="width:20%;">负责代表</div>
            <div style="width:60%;">问题</div>
            <div style="width:20%;">办理状态</div>
          </div>
          <div @click="viewRecords($event)">
          <vue-seamless-scroll :data="problem_list" :class-option="classOption" style="
              width: 100%;
              position: absolute;
              overflow: hidden;
              height: 10vh;
              margin-top: 2vh;
            "
          >
            
            <div class="flex between" v-for="(itm, i) in problem_list" :key="i"
              style="width: 100%; color: #c1c1c1; display: flex; "  type="problem" :data="JSON.stringify(itm)">
              <div style="text-align: center;width:20%;" >{{itm.staff?itm.staff.realname:''}}<!-- {{ itm.category.name }} --></div>
              <div style="text-align: center;width:60%; overflow: hidden;text-overflow: ellipsis;display: -webkit-box; -webkit-box-orient: vertical;-webkit-line-clamp: 1;">{{ itm.content }}</div>
              <div style="text-align: center;width:20%;">{{ itm.status }}</div>
            </div>
          </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
    <div class="rigth">
      <div class="top">
        <div class="title">代表履职分析情况</div>
        <div class="table">
          <div style="width: 100%">
            <div style="color: #00fcff; font-size: 0.9375vw; width: 100%" class="flex">
              <div style="width: 20%; text-align: center">代表</div>
              <div style="width: 20%; text-align: center">姓名</div>
              <div style="width: 30%; text-align: center">单位及职务</div>
              <div style="width: 20%; text-align: center">履职数量</div>
            </div>
          </div>
          
          <vue-seamless-scroll :data="staff_list" :class-option="classOption" style="
              width: 100%;
              position: absolute;
              overflow: hidden;
              height: 25vh;
              margin-top: 2vh;
            ">
            <div v-for="(itm, i) in staff_list" :key="i"
              style="width: 100%; color: #fff; display: flex;margin-top: 0.26042vw;" >
              <div style="width: 20%; text-align: center">

                <img :src="itm.avatar" style="width:2.08333vw;height:2.08333vw;border-radius: 50%;"/>
              </div>
              <div style="width: 20%; text-align: center;line-height:2.08333vw;">{{ itm.realname }}</div>
              <div style="width: 30%; text-align: center;line-height:2.08333vw;">{{ itm.unit }}</div>
              <div style="width: 20%; text-align: center;line-height:2.08333vw; color: #f6c830">
                {{ itm.yb_problem_num }}
              </div>
            </div>
          </vue-seamless-scroll>
          
        </div>
      </div>
      <div class="bottom">
        <div class="title">人大建议办理情况</div>
        <div id="people"></div>
      </div>
    </div>
    <el-dialog :visible.sync="show_dialog" custom-class="dialog" width="80%" top="20vh">
      <div style="
          width: 80%;
          margin: 0 auto;
          border: undefined solid #000 !important;
          z-index: 1;
        ">
        <div v-if="dialog_index == 1">
          <div class="flex">
            <div class="border"></div>
            <div style="color: #fff">意见详情</div>
          </div>

          <div style="
              width: 90%;
              margin: 0 auto;
              margin-top: 0.5vh;
              color: #fff;
              padding: 1vh 5%;
              background-color: rgba(79, 93, 214, 0.14);
            " class="flex between">
            <div>负责代表</div>
            <div>{{problem_itm.staff?problem_itm.staff.realname:''}}</div>
          </div>
          <div style="
              width: 90%;
              margin: 0 auto;
              margin-top: 0.5vh;
              color: #fff;
              padding: 1vh 5%;
              background-color: rgba(79, 93, 214, 0.14);
            " class="flex between">
            <div>办理状态</div> 
            <div :style="problem_itm.status=='已反馈'?'color: red':'color:#11FF11'">{{problem_itm.status}}</div>
          </div>
          <div style="
              width: 90%;
              margin: 0 auto;
              margin-top: 0.5vh;
              color: #fff;
              padding: 1vh 5%;
              background-color: rgba(79, 93, 214, 0.14);
            ">
            <div>反馈内容</div>
            <div style="margin-top: 1vh; height: 20vh; overflow-y: auto">
              {{problem_itm.feedback_content}}
            </div>
          </div>
        </div>

        <div class="article" v-if="dialog_index == 2">
          <div class="flex">
            <div class="border"></div>
            <div style="color: #fff">{{article_title}}</div>
          </div>
          <div class="list">
            <div class="flex between item" v-for="(itm, i) in article_list" :key="i" @click="Setarticle(itm,3)">
              <div style="width: 55%">
                <div class="content">{{itm.title}}</div>
                <div style="margin-top: 2vh; font-size: 1.04167vw; font-weight: bold">
                  {{itm.create_time}}
                </div>
              </div>
              <div style="
                  height: 12vh;
                  background-color: #fff;
                  width: 40%;
                  border-radius: 0.52083vw;
                ">
                <img v-if="itm.cover!=''" :src="itm.cover" style="width:100%;height:100%" alt="">
              </div>
            </div>
          </div>
        </div>
        <div class="details" v-if="dialog_index == 3">
          <div>{{article_itm.title}}</div>
          <div style="font-size: 1.04167vw; margin-top: 1vh">{{article_itm.create_time}}</div>
          <div class="content">
            <img v-if="article_itm.cover!=''" :src="article_itm.cover" style="width:100%;height:100%;border-radius: 0.52083vw;" alt=""/>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import M from "minimatch";
import vueSeamlessScroll from "vue-seamless-scroll";

import { big_data, staff,problem,article } from "../api/apiData";
export default {
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      classOption: {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 0, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000,
      },
      show_dialog: false,
      dialog_index: 0, //1 意见建议  2 学习园地列表  3 详情
      cjdl_data: {},
      problem_data: {},
      gzdt_data: {
        gz: 0,
        xxyd: 0,
        xwdt: 0,
        fk: 0
      },
      index_data: { yb: 0, db: 0 },
      staff_list: [],
      problem_list:[],
      problem_itm:{},
      article_list:[],
      article_itm:{},
      article_title:""
    };
  },
  mounted() {
    this.GetGzdt();
    this.GetIndex()
    this.GetstaffQuery()
    this.GetproblemQuery()
    //echart
    this.GetCjdl();
    this.Getproblem();
  },
  methods: {
    viewRecords(e) {
      
      const path = e.path || (e.composedPath && e.composedPath());
      let target = path.filter((r) => /listItem/.test(r.className));
      
      /* if (target.length) target = target[0];
      else return; */
      let e1
      for(var i=0;i<e.path.length;i++){
        var type = e.path[i].getAttribute("type") 
        
        if(type === "problem"){
          e1 = e.path[i]
          
          break
        }
      }
      
      let item = JSON.parse(e.path[i].getAttribute("data") )

      this.problem_itm = item
      
      this.SetDialogIndex(1)
    },
    Setproblem(itm,index){
      this.problem_itm = itm
      //console.log(itm)
      this.SetDialogIndex(index)
    },
    Setarticle(itm,index){
      this.article_itm = itm
      
      this.SetDialogIndex(index)
    },

    async GetarticleQuery(channel_id,index,title){
      let res = await article.query({channel_id, page: 1, size: 20})
      this.article_list = res.data.list
      this.article_title = title
      this.SetDialogIndex(index)
    },
    async GetproblemQuery(){
      let res = await problem.query({ page: 1, size: 20 })
      this.problem_list = res.data.list
    },
    async GetstaffQuery() {
      let res = await staff.query({ page: 1, size: 20 })
      this.staff_list = res.data.list
    },
    SetDialogIndex(index) {
      this.dialog_index = index;
      this.show_dialog = true;
    },
    async GetIndex() {
      let res = await big_data.index()
      this.index_data = res.data
    },
    async GetGzdt() {
      let res = await big_data.gzdt()
      this.gzdt_data = res.data
    },
    async Getproblem() {
      let res = await big_data.problemAnalysisByMonth()
      this.problem_data = res.data
      this.peopleInit()
    },
    peopleInit() {
      var myChart = this.$echarts.init(document.getElementById("people"));

      let dataX = this.problem_data.xAxis; //名称
      let dataY = this.problem_data.series.yb; //数据
      let dataY1 = this.problem_data.series.db; //数据

      var option = {
        legend: {
          show: true,
          textStyle: {
            color: "rgba(192, 195, 218, 1)",
          },
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        grid: {
          top: '15%',
          right: '5%',
          left: '5%',
          bottom: '12%',
        },
        xAxis: [
          {
            type: 'category',
            data: dataX,
            axisLine: {
              lineStyle: {
                color: 'rgba(66, 192, 255, .3)',
              },
            },
            axisLabel: {
              interval: 0,
              margin: 10,
              color: '#C0C3DA',
              textStyle: {
                fontSize: 11,
              },
              formatter: function (params) {
                // params为x轴文字内容
                var newParamsName = "";
                var paramsNameNumber = params.length;
                var provideNumber = 4; //一行显示几个字
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    var start = p * provideNumber;
                    var end = start + provideNumber;
                    if (p == rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      tempStr = params.substring(start, end) + "\n";
                    }
                    newParamsName += tempStr;
                  }
                } else {
                  newParamsName = params;
                }
                return newParamsName;
              },
            },
            axisTick: {//刻度
              show: false,
            },
          },
        ],
        yAxis: [
          {
            axisLabel: {
              padding: [3, 0, 0, 0],
              formatter: '{value}',
              color: 'rgba(95, 187, 235, 1)',
              textStyle: {
                fontSize: 11,
              },
            },
            axisTick: {
              show: true,
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(66, 192, 255, .3)',
              },
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(255,255,255,0)',
              },
            },
          },
        ],
        dataZoom: [//滚动条
          {
            show: false,
            type: 'slider',
            realtime: true,
            startValue: 0,
            endValue: 14,
            xAxisIndex: [0],
            bottom: '10',
            left: '30',
            height: 10,
            borderColor: 'rgba(0,0,0,0)',
            textStyle: {
              color: '#05D5FF',
            },
          },
        ],
        series: [
          {
            name: "已办",
            type: 'bar',
            data: dataY,
            barWidth: '10',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(54, 69, 176, 0.5500)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(106, 124, 250, 1)', // 100% 处的颜色
                    },
                  ],
                  false
                )
              },
            },
            label: {
              normal: {
                show: true,
                lineHeight: 10,
                formatter: '{c}',
                position: 'top',
                textStyle: {
                  color: '#fff',
                  fontSize: 10,
                },
              },
            },
          },
          {
            name: "待办",
            type: 'bar',
            data: dataY1,
            barWidth: '10',
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(1, 193, 243, 0.1500)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(7, 163, 209, 1)', // 100% 处的颜色
                    },
                  ],
                  false
                )
              },
            },
            label: {
              normal: {
                show: true,
                lineHeight: 10,
                formatter: '{c}',
                position: 'top',
                textStyle: {
                  color: '#fff',
                  fontSize: 10,
                },
              },
            },
          },
        ],
      };


      window.onresize = myChart.resize;
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);
    },
    async GetCjdl() {
      let res = await big_data.cjbl();
      this.cjdl_data = res.data;
      this.villageInit();
    },
    villageInit() {
      var myChart = this.$echarts.init(document.getElementById("village"));

      // 指定图表的配置项和数据
      var option = {
        legend: {
          show: true,
          textStyle: {
            color: "rgba(192, 195, 218, 1)",
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "rgba(0, 255, 233,0)",
                  },
                  {
                    offset: 0.5,
                    color: "rgba(255, 255, 255,1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(0, 255, 233,0)",
                  },
                ],
                global: false,
              },
            },
          },
        },
        grid: {
          top: "15%",
          left: "8%",
          right: "5%",
          bottom: "15%",
          // containLabel: true
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              show: true,
            },
            splitArea: {
              // show: true,
              color: "#f00",
              lineStyle: {
                color: "#f00",
              },
            },
            axisLabel: {
              color: "#C0C3DA",
              formatter: function (params) {
                // params为x轴文字内容
                var newParamsName = "";
                var paramsNameNumber = params.length;
                var provideNumber = 2; //一行显示几个字
                var rowNumber = Math.ceil(paramsNameNumber / provideNumber);
                if (paramsNameNumber > provideNumber) {
                  for (var p = 0; p < rowNumber; p++) {
                    var tempStr = "";
                    var start = p * provideNumber;
                    var end = start + provideNumber;
                    if (p == rowNumber - 1) {
                      tempStr = params.substring(start, paramsNameNumber);
                    } else {
                      tempStr = params.substring(start, end) + "\n";
                    }
                    newParamsName += tempStr;
                  }
                } else {
                  newParamsName = params;
                }
                return newParamsName;
              },
            },
            splitLine: {
              show: false,
            },
            boundaryGap: false,
            data: this.cjdl_data.xAxis.data,
          },
        ],

        yAxis: [
          {
            type: "value",
            show: true,
            min: 0,
            // max: 140,
            splitNumber: 4,
            splitLine: {
              show: true,
              lineStyle: {
                color: "rgba(255,255,255,0.1)",
              },
            },
            axisLine: {
              show: false,
            },
            axisLabel: {
              show: true,
              margin: 10,
              textStyle: {
                color: "#d1e6eb",
              },
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: this.cjdl_data.series[0].name,
            type: "line",
            // smooth: true, //是否平滑
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbol: "circle",
            symbolSize: 10,
            lineStyle: {
              normal: {
                color: "rgba(0, 255, 255, 1)",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#6c50f3",
              },
            },
            itemStyle: {
              color: "rgba(0, 255, 255, 1)",
              borderWidth: 3,
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 2,
              shadowOffsetX: 2,
            },
            tooltip: {
              show: true,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(0, 255, 255, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgb(23,55,79)",
                    },
                  ],
                  false
                ),
              },
            },
            data: this.cjdl_data.series[0].data,
          },
          {
            name: this.cjdl_data.series[1].name,
            type: "line",
            // smooth: true, //是否平滑
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbol: "circle",
            symbolSize: 10,
            lineStyle: {
              normal: {
                color: "rgba(255, 73, 178, 1)",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#00ca95",
              },
            },

            itemStyle: {
              color: "rgba(255, 73, 178, 1)",
              borderWidth: 3,
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 2,
              shadowOffsetX: 2,
            },
            tooltip: {
              show: false,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(255, 73, 178, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgb(45,37,78)",
                    },
                  ],
                  false
                ),
              },
            },
            data: this.cjdl_data.series[1].data,
          },
          {
            name: this.cjdl_data.series[2].name,
            type: "line",
            // smooth: true, //是否平滑
            showAllSymbol: true,
            // symbol: 'image://./static/images/guang-circle.png',
            symbol: "circle",
            symbolSize: 10,
            lineStyle: {
              normal: {
                color: "rgba(34, 143, 254, 1)",
                shadowColor: "rgba(0, 0, 0, .3)",
                shadowBlur: 0,
                shadowOffsetY: 5,
                shadowOffsetX: 5,
              },
            },
            label: {
              show: true,
              position: "top",
              textStyle: {
                color: "#00ca95",
              },
            },

            itemStyle: {
              color: "rgba(34, 143, 254, 1)",
              borderWidth: 3,
              shadowColor: "rgba(0, 0, 0, .3)",
              shadowBlur: 0,
              shadowOffsetY: 2,
              shadowOffsetX: 2,
            },
            tooltip: {
              show: false,
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(34, 143, 254, 1)",
                    },
                    {
                      offset: 1,
                      color: "rgb(20,57,96)",
                    },
                  ],
                  false
                ),
              },
            },
            data: this.cjdl_data.series[2].data,
          },
        ],
      };

      // 使用刚指定的配置项和数据显示图表。
      window.onresize = myChart.resize;
      myChart.setOption(option);
    },
  },
};
</script>
<style lang="less">
.dialog {
  background: url(../assets/img/details.png) no-repeat center;
  background-size: 100% 100%;
  width: 50vw !important;
  height: 50vh !important;
}

.el-dialog__body {
  padding: 2vh calc(var(--el-dialog-padding-primary) + 0.5vh) !important;
}

.el-dialog__title {
  color: #fff !important;
  font-size: 20px;
  font-weight: bold !important;
  display: none;
}
</style>
<style lang="less" scoped>
.index {
  position: absolute;
  top: 10vh;
  left: 5%;
  width: 90%;
  height: 83vh;
  margin: 0 auto;

  .left {
    width: 33%;

    .top {
      background: url(../assets/img/lt.png) no-repeat center;
      background-size: 100% 100%;
      width: 100%;
      height: 40vh;
      position: relative;

      .title {
        position: absolute;
        left: 2.3vw;
        top: 2.6vh;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }

      .container {
        width: 80%;
        height: 75%;
        position: absolute;
        left: 1.5vw;
        top: 10vh;
        /* border: 1px solid #fff; */
        flex-wrap: wrap;

        .grid {
          margin-left: 1vw;
          flex:1;
          .logo-1 {
            background: url(../assets/img/logo-1.png) no-repeat center;
            background-size: 100% 100%;
            width: 60px;
            height: 60px;
          }

          .logo-2 {
            background: url(../assets/img/logo-2.png) no-repeat center;
            background-size: 100% 100%;
            width: 60px;
            height: 60px;
          }

          .logo-3 {
            background: url(../assets/img/logo-3.png) no-repeat center;
            background-size: 100% 100%;
            width: 60px;
            height: 60px;
          }

          .logo-4 {
            background: url(../assets/img/logo-4.png) no-repeat center;
            background-size: 100% 100%;
            width: 60px;
            height: 60px;
          }
        }
      }
    }

    .bottom {
      background: url(../assets/img/lb.png) no-repeat center;
      background-size: 100% 100%;
      width: 100%;
      height: 40vh;
      margin-top: 1vh;
      position: relative;

      .title {
        position: absolute;
        left: 2.3vw;
        top: 2.6vh;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }

      #village {
        position: absolute;
        left: 2vw;
        top: 8vh;
        width: 84%;
        margin: 0 auto;

        height: 30vh;
      }
    }
  }

  .center {
    width: 33%;
    /* border: 1px solid #fff; */
    position: relative;

    .num {
      width: 90%;
      margin: 0 auto;
      margin-top: 2vh;

      .tips-bg {
        background: url(../assets/img/tips-bg.png) no-repeat center;
        background-size: 100% 100%;
        width: 40%;
        height: 8vh;
        margin-left: 2vw;
        position: relative;
      }
    }

    .bottom {
      position: absolute;
      background: url(../assets/img/cb.png) no-repeat center;
      background-size: 100% 100%;
      width: 90%;
      height: 20vh;
      bottom: 2vh;
      left: 5%;

      .title {
        color: #fff;
        font-size: 10px;
        position: absolute;
        top: 0.5vh;
        left: 2vw;
      }

      .content {
        position: absolute;
        top: 4vh;
        left: 2.3vw;
        width: 80%;
        font-size: 10px;
      }
    }
  }

  .rigth {
    width: 33%;

    .top {
      background: url(../assets/img/rt.png) no-repeat center;
      background-size: 100% 100%;
      width: 100%;
      height: 40vh;
      position: relative;

      .title {
        position: absolute;
        right: 2.3vw;
        top: 2.6vh;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }

      .table {
        position: absolute;
        top: 8vh;
        left: 3.5vw;
        width: 80%;
      }
    }

    .bottom {
      background: url(../assets/img/rb.png) no-repeat center;
      background-size: 100% 100%;
      width: 100%;
      height: 40vh;
      margin-top: 1vh;
      position: relative;

      .title {
        position: absolute;
        right: 2.3vw;
        top: 2.6vh;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }

      #people {
        position: absolute;
        left: 2vw;
        top: 8vh;
        width: 84%;
        margin: 0 auto;

        height: 30vh;
      }
    }
  }

  .ball {
    background: url(../assets/img/ball.png) no-repeat center;
    background-size: 100% 100%;
    width: 46%;
    height: 60vh;
    position: absolute;
    top: 10vh;
    left: 27%;
    z-index: -1;
  }
}

.border {
  width: 0.2vw;
  height: 3.5vh;
  border-radius: 10px;
  margin-right: 0.5vw;
  background-color: rgba(43, 192, 239, 1);
}

.article {
  .list {
    height: 36vh;
    overflow-y: auto;
    margin-top: 10px;

    .item {
      width: 98%;
      margin: 0 auto;
      margin-top: 1vh;
      color: #fff;
      padding: 1vh 1%;
      background-color: rgba(79, 93, 214, 0.14);
      border-radius: 10px;

      .content {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
}

.details {
  color: #fff;

  .content {
    margin-top: 10px;
    height: 28vh;
    border-radius: 10px;
   /*  max-height: 31vh; */
    //overflow-y: auto;
  }
}
</style>